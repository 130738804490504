import { SET_PARISHES, SET_PERMIT_TYPES } from '../actions/general';

const defaultState = {
  parishes: {},
  permitTypes: {},
};

const generalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_PARISHES:
      return {
        ...state,
        parishes: action.payload,
      };
    case SET_PERMIT_TYPES:
      return {
        ...state,
        permitTypes: action.payload,
      };
    default:
      return state;
  }
};

export default generalReducer;
