export const CLEAR_STORE = 'CLEAR_STORE';
export const SET_PARISHES = 'SET_PARISHES';
export const SET_PERMIT_TYPES = 'SET_PERMIT_TYPES';

export const setParishes = parishes => ({
  type: SET_PARISHES,
  payload: parishes,
});

export const setPermitTypes = permitTypes => ({
  type: SET_PERMIT_TYPES,
  payload: permitTypes,
});

// Tells the root reducer to completely clear the redux store, used for 401 encounters
export const clearStore = () => ({ type: CLEAR_STORE, payload: true });
