import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-latest';
import Multiselect from 'multiselect-react-dropdown';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { parishesSelector, permitTypesSelector } from '../store/selectors/general';
import { Button } from './Button';
import {
  useLazyGetSubscriptionDataQuery,
  useAddUpdateSubscriptionMutation,
  useUnsubscribeMutation,
} from '../store/services/api';
import { errorToast } from '../utils/toast';
import { Checkbox } from './Checkbox';

const SOUTHWEST_LOUISIANA_PARISHES = [
  'Allen',
  'Beauregard',
  'Calcasieu',
  'Cameron',
  'Jefferson Davis',
];

const CANCER_ALLEY_PARISHES = [
  'Plaquemines',
  'Iberville',
  'West Baton Rouge',
  'East Baton Rouge',
  'Ascension',
  'St. James',
  'St. John the Baptist',
  'St. Charles',
  'Jefferson',
  'Orleans',
];

/*  
  This component allows users to manage their subscription preferences. 
  * This page will display an option to subscribe for the first time using their email and selecting parishes
  * Accessing through their email, this page will only display the subscription update options (changing parishes / unsubscribing).
*/
export const EmailSubscriptions = () => {
  const navigate = useNavigate();
  const { subscriptionId } = useParams();

  const [parishesSelectAll, setParishesSelectAll] = useState(false);

  const parishes = useSelector(parishesSelector);
  const permitTypes = useSelector(permitTypesSelector);

  const [getSubscriptionTrigger, result] = useLazyGetSubscriptionDataQuery();
  const { data, isFetching, isError } = result;

  const [unsubscribeTrigger] = useUnsubscribeMutation();
  const [addUpdateSubscriptionTrigger] = useAddUpdateSubscriptionMutation();

  const saveEmailSubscription = values => {
    addUpdateSubscriptionTrigger({
      email: values.email,
      permitTypes: values.permitTypes,
      parishes: values.parishes,
    })
      .unwrap()
      .then(_ => {
        navigate('/email-preferences/confirmation', { state: { unsubscribed: false } });
      })
      .catch(error => {
        console.error(error);
        errorToast('Unable to save your email subscription preferences. Please try again later.');
      });
  };

  const emailUnsubscribe = () => {
    if (formik.values.email) {
      unsubscribeTrigger({ email: formik.values.email })
        .unwrap()
        .then(_ => {
          navigate('/email-preferences/confirmation', { state: { unsubscribed: true } });
        })
        .catch(error => {
          console.error(error);
          errorToast('Unable to unsubscribe your email. Please try again later.');
        });
    } else {
      errorToast('Please enter a valid email to unsubscribe.');
    }
  };

  const validateEmailSubscription = values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    }
    if (!values.permitTypes.length) {
      errors.permitTypes = 'Select at least one permit type';
    }
    if (!values.parishes.length) {
      errors.parishes = 'Select at least one parish';
    }
    // Disable the save button if the user has not changed their email, permit type, parish selection
    if (
      values.email === formik.initialValues.email &&
      JSON.stringify(values.permitTypes) === JSON.stringify(formik.initialValues.permitTypes) &&
      JSON.stringify(values.parishes) === JSON.stringify(formik.initialValues.parishes)
    ) {
      errors.sameContent = 'Please make updates to your email subscription';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      permitTypes: [],
      parishes: [],
    },
    validate: validateEmailSubscription,
    onSubmit: saveEmailSubscription,
  });

  const handleSelectAllParishes = () => {
    setParishesSelectAll(!parishesSelectAll);
    if (parishesSelectAll) {
      formik.setFieldValue('parishes', parishes);
    } else {
      formik.setFieldValue('parishes', []);
    }
  };

  // If user selects "Southwest Louisiana" also select:
  // "Allen", "Beauregard", "Calcasieu", "Cameron", "Jefferson Davis"
  const handleSelectSouthwestLouisiana = isChecked => {
    let finalSouthwestLousianaParishes;

    if (isChecked) {
      // After checking if some of Southwest Louisiana's parishes are already selected, add the rest
      finalSouthwestLousianaParishes = [
        ...formik.values.parishes,
        ...SOUTHWEST_LOUISIANA_PARISHES.filter(parish => !formik.values.parishes.includes(parish)),
      ];
    } else {
      // Deselect Southwest Louisiana's parishes
      finalSouthwestLousianaParishes = formik.values.parishes.filter(
        parish => !SOUTHWEST_LOUISIANA_PARISHES.includes(parish),
      );
    }

    formik.setFieldValue('parishes', finalSouthwestLousianaParishes);
  };

  // If user selects "Cancer Alley" also select:
  // "Plaquemines", "Iberville", "West Baton Rouge", "East Baton Rouge", "Ascension",
  // "St James", "St John the Baptist", "St Charles", "Jefferson", "Orleans"
  const handleSelectCancerAlley = isChecked => {
    let finalCancerAlleyParishes;

    if (isChecked) {
      // After checking if some of Southwest Louisiana's parishes are already selected, add the rest
      finalCancerAlleyParishes = [
        ...formik.values.parishes,
        ...CANCER_ALLEY_PARISHES.filter(parish => !formik.values.parishes.includes(parish)),
      ];
    } else {
      // Deselect Cancer Alley's parishes
      finalCancerAlleyParishes = formik.values.parishes.filter(
        parish => !CANCER_ALLEY_PARISHES.includes(parish),
      );
    }

    formik.setFieldValue('parishes', finalCancerAlleyParishes);
  };

  useEffect(() => {
    if (!subscriptionId) {
      formik.setValues({
        email: '',
        permitTypes: permitTypes,
        parishes: parishes,
      });
    } else {
      if (isFetching) {
        return; // Disable search when fetching to prevent race condition
      }
      getSubscriptionTrigger({ subscriptionId: subscriptionId });
    }

    if (data) {
      const userSubscription = data?.subscription;
      formik.setValues({
        email: userSubscription.email || '',
        permitTypes: userSubscription.permit_types || [],
        parishes: userSubscription.parishes || [],
      });
    }
  }, [subscriptionId, permitTypes, parishes, getSubscriptionTrigger, data]);

  if (isError) {
    return (
      <p className="absolute w-full h-full flex justify-center items-center">
        Couldn't find your email subscription. Please try again.
      </p>
    );
  }

  return (
    <div className="absolute w-full md:h-full flex justify-center items-center">
      {!parishes.length || !permitTypes.length || isFetching ? (
        <p>Loading...</p>
      ) : (
        <div className="flex flex-col w-fit max-h-xl max-w-3xl p-8 overflow">
          <h1>LAFS Permit Tracker Email Subscription</h1>
          <p className="mt-4 mb-8">
            Subscribe to receive email updates on the latest permits in the selected parishes.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex gap-10 w-full">
              <div className="flex flex-col gap-2">
                <label htmlFor="email" className="text-primary">
                  Email *
                </label>
                <input
                  id="email"
                  type="email"
                  className="w-72 rounded-none"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="formik-error">{formik.errors.email}</p>
                ) : null}
              </div>
            </div>

            <div className="my-4">
              <label className="text-primary">Permit Types *</label>
              <div className="w-72">
                <Multiselect
                  id="permit-types-email-subscription"
                  options={permitTypes}
                  isObject={false}
                  selectedValues={formik.values.permitTypes}
                  onSelect={selectedPermitTypesList =>
                    formik.setFieldValue('permitTypes', selectedPermitTypesList)
                  }
                  onRemove={selectedPermitTypesList =>
                    formik.setFieldValue('permitTypes', selectedPermitTypesList)
                  }
                  showCheckbox
                  closeIcon="cancel"
                  avoidHighlightFirstOption
                  keepSearchTerm
                  placeholder="Select permit types..."
                  emptyRecordMsg="No permit types found"
                  style={{
                    searchBox: {
                      borderRadius: '0px',
                      minHeight: '39px',
                      marginTop: '5px',
                    },
                    inputField: { height: '20px' },
                    chips: { background: '#366499' },
                  }}
                />
              </div>
              {formik.touched.permitTypes && formik.errors.permitTypes ? (
                <p className="formik-error mt-2">{formik.errors.permitTypes}</p>
              ) : null}
            </div>

            <div className="my-4 md:w-full ">
              <div className="flex justify-between items-center w-[95%] md:w-full">
                <label className="text-primary">Parishes *</label>
                <button
                  className="link-button my-2 !text-sm md:!text-base"
                  onClick={handleSelectAllParishes}
                  type="button"
                >
                  {parishesSelectAll ? 'Select' : 'Deselect'} all parishes
                </button>
              </div>
              <div className="max-w-[95%] md:max-w-[600px]">
                <Multiselect
                  id="parishes-email-subscription"
                  options={parishes}
                  isObject={false}
                  selectedValues={formik.values.parishes}
                  onSelect={selectedParishesList =>
                    formik.setFieldValue('parishes', selectedParishesList)
                  }
                  onRemove={selectedParishesList =>
                    formik.setFieldValue('parishes', selectedParishesList)
                  }
                  showCheckbox
                  closeIcon="cancel"
                  avoidHighlightFirstOption
                  keepSearchTerm
                  placeholder="Select parishes..."
                  emptyRecordMsg="No parishes found"
                  style={{
                    searchBox: {
                      borderRadius: '0px',
                      marginTop: '5px',
                      minHeight: '39px',
                      maxHeight: '110px',
                      maxWidth: '600px',
                      overflow: 'scroll',
                    },
                    inputField: { height: '20px' },
                    chips: { background: '#366499' },
                  }}
                />
              </div>
              <div className="flex gap-10">
                <Checkbox
                  label="Add Southwest Louisiana"
                  handleCheckboxChange={handleSelectSouthwestLouisiana}
                />
                <Checkbox label="Add Cancer Alley" handleCheckboxChange={handleSelectCancerAlley} />
              </div>

              {formik.touched.parishes && formik.errors.parishes ? (
                <p className="formik-error">{formik.errors.parishes}</p>
              ) : null}
            </div>

            {formik.errors.sameContent ? (
              <p className="formik-error mt-8">{formik.errors.sameContent}</p>
            ) : null}

            <div className="mt-6">
              <Button type="submit">{subscriptionId ? 'Save Changes' : 'Subscribe'}</Button>
            </div>

            {subscriptionId && (
              <div className="mt-6">
                <hr></hr>
                <h2 className="mt-4">Unsubscribe</h2>
                <p className="my-4">We're sorry to see you go!</p>
                <button className="link-button" type="button" onClick={emailUnsubscribe}>
                  Unsubscribe from these emails
                </button>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
};
