import { useLocation } from 'react-router-dom-latest';

export const SubscriptionConfirmation = () => {
  const { state: unsubscribedState } = useLocation();

  return (
    <div className="absolute w-full h-full flex justify-center items-center">
      {unsubscribedState.unsubscribed ? (
        <p>You have successfully unsubscribed from the LAFS Permit Tracker</p>
      ) : (
        <p>Your subscription preferences have been successfully saved!</p>
      )}
    </div>
  );
};
