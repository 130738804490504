import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import generalReducer from './reducers/general';
// import modalsReducer from './reducers/modals';

import { api } from './services/api';
import { CLEAR_STORE } from './actions/general';

const reducer = combineReducers({
  [api.reducerPath]: api.reducer,
  general: generalReducer,
//   modals: modalsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const middleware = [api.middleware];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middleware.push(logger);
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
});

export default store;
