import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom-latest';
import { ToastContainer } from 'react-toastify';

import useGetAppConsts from './hooks/useGetAppConsts';
import { EmailSubscriptions } from './components/EmailSubscriptions';
import { SubscriptionConfirmation } from './components/SubscriptionConfirmation';

import store from './store';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const Router = () => {
  useGetAppConsts();

  return (
    <BrowserRouter>
      <Routes>
        {/* these are just placeholder routes - subscribe should be for new subscriptions, email-preferences should be for existing ones. they can use the same queries though */}
        <Route path="/" element={<EmailSubscriptions />} />
        <Route path="/email-preferences/:subscriptionId" element={<EmailSubscriptions />} />
        <Route path="/email-preferences/confirmation" element={<SubscriptionConfirmation />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

const App = () => (
  <Provider store={store}>
    <Router />
  </Provider>
);

export default App;
