import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_ROOT = '/api';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  endpoints: build => ({
    // Add endpoints here
    // See: https://redux-toolkit.js.org/rtk-query/usage/queries
    getSubscriptionData: build.query({
      query: values => {
        let queryParams = [];
        if (values.subscriptionId) {
          queryParams.push(['id', values.subscriptionId]);
        }
        const query = queryParams.length ? `?${queryParams.map(q => q.join('=')).join('&')}` : '';
        return `/subscription/search${query}`;
      },
    }),

    addUpdateSubscription: build.mutation({
      query: values => ({
        url: '/subscription/add-update',
        method: 'POST',
        body: values,
      }),
    }),
    unsubscribe: build.mutation({
      query: values => ({
        url: '/subscription/unsubscribe',
        method: 'POST',
        body: values,
      }),
    }),
  }),
});

// These export hooks are automatically defined by createApi, they
// can be imported in functional components to fetch data, see fetch
// status and get the data from the redux store.
export const {
  useGetSubscriptionDataQuery,
  useLazyGetSubscriptionDataQuery,
  useAddUpdateSubscriptionMutation,
  useUnsubscribeMutation,
} = api;
