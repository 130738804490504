export const Checkbox = ({ label, handleCheckboxChange }) => {
  return (
    <label className="flex items-center gap-2 my-4">
      <input
        type="checkbox"
        onChange={e => {
          handleCheckboxChange(e.target.checked);
        }}
      />
      <p className="text-left font-semibold text-primary text-sm md:text-base">{label}</p>
    </label>
  );
};
