import clsx from 'clsx';

export const Button = ({ inverted, disabled, isLoading, small, ...props }) => {
  return (
    <div
      className={clsx(
        'relative font-medium',
        disabled || isLoading ? 'cursor-default' : 'cursor-pointer',
      )}
    >
      <button
        className={clsx(
          'flex min-w-40 cursor-pointer items-center justify-center gap-4 rounded-md border text-lg shadow transition-all',
          small ? 'px-4 py-2' : 'px-6 py-4',
          inverted
            ? 'border-primary bg-white text-primary hover:bg-primary-50'
            : 'border-primary bg-primary text-white hover:bg-primary-400',
          disabled || isLoading ? 'pointer-events-none opacity-50' : 'opacity-100',
        )}
        {...props}
      ></button>
    </div>
  );
};
