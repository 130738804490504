import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { clearStore, setParishes, setPermitTypes } from '../store/actions/general';
import { errorToast } from '../utils/toast';

/**
 * Hook responsible for fetching consts that we need to use
 * across the app to populate dropdowns and connect them to
 * their ids.
 */
const useGetAppConsts = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const getAppConsts = async () => {
      const getParishes = async () => {
        const rawResp = await fetch('/api/constants/parishes');
        const jsonResp = await rawResp.json();
        return jsonResp.PARISHES;
      };
      const getPermitTypes = async () => {
        const rawResp = await fetch('/api/constants/permit-types');
        const jsonResp = await rawResp.json();
        return jsonResp.PERMIT_TYPES;
      };
      try {
        const [parishes, permitTypes] = await Promise.all([getParishes(), getPermitTypes()]);
        dispatch(setParishes(parishes));
        dispatch(setPermitTypes(permitTypes));
      } catch (error) {
        errorToast('Something went wrong.');
        dispatch(clearStore());
      }
    };

    getAppConsts();
  }, [dispatch]);
};

export default useGetAppConsts;
